export default {
    'LOGIN': {
        'TITLE': 'Bei akii anmelden',
        'SUBTITLE': 'Bitte geben Sie Ihre Handynummer inklusive<br>Ländervorwahl ein:',
        'SUBTITLE_OTHER_INFO': '(Sie können sich nur auf dieser Webseite anmelden, <br/>wenn Sie schon ein Konto in der akii-App haben.)',
        'FOOTER_INFO': 'Möchten Sie ein Konto erstellen? Sie können sich in<br> der akii-App registrieren.',
        'FOOTER_OTHER_INFO': 'Apple und das Apple Logo sind in der USA und anderen Ländern eingetragene<br>Marken von Apple Inc.<br>Google Play und das Google Play-Logo sind Marken von Google LLC.',
        'LOGIN': 'Anmelden',
        'CONTINUE': 'Weiter',
        'ERROR': {
            'NETWORK_ERROR': 'Es gibt ein Problem mit der Internetverbindung.'
        }
    },
    'VERIFY_TOKEN': {
        'TITLE': 'Bestätigungscode',
        'SUBTITLE': 'Wir haben einen Bestätigungscode per SMS an<br>diese Nummer gesendet: {phoneNumber}.',
        'SUBTITLE_THIRD_BLOCK': 'Sie haben keine SMS erhalten? Warten Sie einen<br>\nMoment oder fordern Sie einen neuen Code an.',
        'CODE': 'Bestätigungscode',
        'ERROR': {
            'WRONG_CODE': 'Sie haben einen falschen Code eingegeben, bitte versuchen Sie es erneut.'
        }
    },
    'LOCKS_GROUPS':{
        'ALREADY_IN_A_GROUP': 'schon in einer Gruppe',
        'ALREADY_IN_THE_GROUP': 'schon in der Gruppe',
        'WILL_BE_MOVED_IN_THIS_GROUP': 'wird in diese Gruppe verschoben',
        'WILL_BE_REMOVED': 'wird aus der Gruppe entfernt',
        'WILL_BE_MOVED_HERE': 'wird in diese Gruppe verschoben',
        'DELETE_HEADER': 'Gruppe löschen?',
        'DELETE_TEXT':'Wollen Sie die Gruppe {groupName} wirklich löschen? Die Aktion kann nicht rückgängig gemacht werden.',
        'CHOSEN_NOT_DELETED':'Die ausgewählten Schlösser werden nicht gelöscht.'
    },
    'HOME': {
        'ALL_ROOMS': 'Alle Schlösser',
        'PRIVACY_POLICY': 'Datenschutzerklärung',
        'TERMS_OF_USE': 'Nutzungsbedingungen',
        'LOGOUT': 'Abmelden',
        'INFO_TEXT': 'Um die Zugänge zu ändern, klicken Sie auf die Buttons in der Tabelle unten.',
        'INFO_TEXT_TRANSPONDERS': 'Die gezeigten Symbole dienen nur zu Informationszwechen und können nicht verändert werden.',
        'INVITATION_SENT': 'Einladung versendet',
        'ACCESS': 'Zugang',
        'ACCESSES': 'Zugänge',
        'NO_ACCESS': 'Kein Zugang',
        'MIXED': 'Gemischte Rechte',
        'ADMIN': 'Admin',
        'NAME': 'Name',
        'NOTHING': 'Keine Gruppe',
        'COMPANY': 'Firma',
        'PHONE_NUMBER': 'Telefonnummer',
        'GROUP': 'Gruppe',
        'SUBGROUP': 'Untergruppe',
        'GROUP_BY': 'Gruppieren nach:',
        'LOCK_GROUP': 'Schlossgruppe',
        'LOCK_GROUPS': 'Schlossgruppen',
        'SORT_BY': 'Sortieren nach:',
        'VIEW': 'Ansicht:',
        'NUMBER_OF_CHANGES': 'keine Änderungen | 1 Recht geändert | {count} Rechte geändert',
        'DISCARD': 'Verwerfen',
        'DELETE': 'Löschen',
        'OWNER': 'Eigentümer',
        'CARD_NUMBER': 'Kartenummer',
        'EXPIRING_DATE': 'Gültig bis',
        'USERS': 'Nutzer',
        'SEARCH': 'Suchen',
        'USER_SEARCH': 'Nutzersuche',
        'START_DATE': 'Startdatum',
        'END_DATE': 'Enddatum',
        'TRANSPONDERS': 'Transponders',
        'ALERT': {
            'TITLE': 'Willkommen!',
            'NO_USERS': 'Es gibt noch keine Nutzer. Fügen Sie den ersten Nutzer hinzu.',
            'INFO': '<div>Nachdem Sie Nutzer hinzugefügt haben, können Sie Zugangs- und</div><div>Verwaltungsrechte für Ihre Schlösser gewähren oder entziehen.</div>',
        },
        'MESSAGES': {
            'USER_DELETED': 'Der Nutzer wurde gelöscht.',
            'GROUP_DELETED': 'Die Gruppe wurde gelöscht.',
            'GROUP_CREATED': 'Die Gruppe wurde erstellt.',
            'CHANGES_SAVED': 'Die Änderungen wurden gespeichert.',
            'TOOLTIP_RIGHTS_FROM_OTHERS': '{username} hat auch Rechte zu {lockname} von {otherManagersList}.',
            'MISSING_FIELDS': 'Es wurden nicht alle Eingabefelder ausgefüllt.',
            'USER_ALREADY_PRESENT': 'Benutzer bereits vorhanden.'
        },
        'ERROR': {
            'DELETING_USER_FAILED': 'Der Nutzer kann nicht gelöscht werden',
            'ERROR_ADDING_YOURSELF': 'Sie können nicht sich selbst hinzufügen.',
            'ADDING_USER_FAILED': 'Beim Hinzufügen des Nutzers ist ein Fehler aufgetreten.',
            'UPDATE_ACCESS_FAILED': 'Beim Speichern der Berechtigungen ist ein Fehler aufgetreten.',
            'SAVE_FAILED': 'Beim Speichern ist ein Fehler aufgetreten.',
            'E1201': 'Der akii Support Nutzer kann nicht entfernt werden.',
            'E1202': 'Nur Manager dürfen andere Nutzer entfernen.',
            'E1203': 'Der zu löschende Nutzer existiert nicht.',
            'E1206': 'Der Nutzer kann nicht gelöscht werden, da er Rechte vom akii Support erhalten hat.',
        },
        'EDIT_USER': {
            'HEADER': 'Nutzerdaten ändern',
        },
        'EDIT_LOCK': {
            'HEADER': 'Schloss-Einstellungen',
            'BODY': 'Schlossname ändern',
            'NAME': 'Raumname',
            'LAST_CHECKED': 'Zuletzt überprüft:',
            'BATTERY_NOT_SET': 'Noch kein Status verfügbar',
            'BATTERY_OK': 'Ok: nicht wechseln',
            'BATTERY_LOW': 'Fast leer: bitte wechseln',
            'BATTERY_STATUS': 'Batteriestatus',
        },
        'LOCK_GROUP_SELECT': {
            'HEADER': 'Schlossgruppe',
            'NEW_HEADER': 'Schlossgruppe erstellen',
            'NEW_HEADER_SUB': 'Schlossuntergruppe erstellen',
            'EDIT_HEADER': 'Schlossgruppe bearbeiten',
            'EDIT_HEADER_SUB': 'Schlossuntergruppe bearbeiten',
            'INFO': 'Geben Sie der Gruppe einen Namen.',
            'INFO_SUB': 'Geben Sie der Untergruppe einen Namen.',
            'CREATE': 'Erstellen',
            'GROUP_NAME': 'Name',
            'DESCRIPTION': 'Beschreibung',
            'DELETE_LOCK_GROUP': 'Gruppe löschen',
            'ADD_LOCKS_TO_GROUP': 'Schlösser hinzufügen',
            'ADD_LOCKS_INFO': 'Wählen Sie die Schlösser aus, die Sie der Gruppe hinzufügen möchten:'
        },
        'PROTOCOL' : {
            'BODY': 'Schlossprotokoll',
        },
        'NEW_USER': {
            'HEADER': 'Nutzer hinzufügen',
            'INFO': 'Bitte geben Sie die folgenden Daten der Person ein, der Sie Zugang gewähren möchten:',
            'FIRST_NAME': 'Vorname',
            'LAST_NAME': 'Nachname',
            'COMPANY': 'Firma',
            'PHONE_NUMBER': 'Telefonnummer ( +49 ... )',
        },
        'DELETE_USER': {
            'HEADER': 'Nutzer löschen?',
            'BODY': 'Wollen Sie den Nutzer {username} wirklich löschen? Die Aktion kann nicht wiederrufen werden.'
        },
        'DELETE_CARD': {
            'HEADER': 'Karte löschen?',
            'BODY': 'Wollen Sie den Transponder {cardid} wirklich löschen? Die Aktion kann nicht wiederrufen werden.',
            'BODY_ADD': 'Achtung: Die Rechte des Transponders werden ers blockiert, wenn Sie die entsprechenden Schlösser mit der akii-App aktivieren (öffnen oder schließen).'
        },
        'DISCARD_CHANGES': {
            'HEADER': 'Änderungen verwerfen?',
            'BODY': 'Sie haben einen Nutzer bearbeitet. Wenn Sie die Änderung verwerfen, kann die Aktion nicht rückgängig gemacht werden.'
        },
        'DISCARD_ACCESS_CHANGES': {
            'HEADER': 'Rechte verwerfen?',
            'BODY': 'Keine | Sie haben 1 Recht geändert. Wenn Sie die Änderung verwerfen, kann die Aktion nicht rückgängig gemacht werden. | Sie haben {count} Rechte geändert. Wenn Sie die Änderung verwerfen, kann die Aktion nicht rückgängig gemacht werden.'
        }
    }
}
