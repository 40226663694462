<template>
  <div>
    <div class="container">
      <Spinner v-if="loading"/>
      <div id="header">
        <div class="header-content">
          <div class="header-logo" >
            <img class="header-logo-img" alt="akii logo" src="../../assets/logo.png">
            <div v-if="this.tableLoading === false && this.loading === false && this.listOfChanges.length === 0" class="tabs-container">
              <div v-on:click="lockGroupSwitch(false)" :class="{'tab-selected':showLockGroupTab === false, 'tab-not-selected':showLockGroupTab === true}" class="tabsItem">{{ $t('HOME.ACCESSES') }}</div>
              <div v-on:click="lockGroupSwitch(true)" :class="{'tab-selected':showLockGroupTab === true, 'tab-not-selected':showLockGroupTab === false}" class="tabsItem">{{ $t('HOME.LOCK_GROUPS') }}</div>
            </div>
            <div v-if="this.tableLoading !== false || this.loading !== false || this.listOfChanges.length > 0" class="tabs-container-disabled">
              <div :class="{'tab-selected':showLockGroupTab === false, 'tab-not-selected-disabled':showLockGroupTab === true}" class="tabsItem">{{ $t('HOME.ACCESSES') }}</div>
              <div :class="{'tab-selected':showLockGroupTab === true, 'tab-not-selected-disabled':showLockGroupTab === false}" class="tabsItem">{{ $t('HOME.LOCK_GROUPS') }}</div>
            </div>
          </div>
          <div class="user-status">
            <img v-on:click="openAddUserModal()" class="add-button" :src="buttonAddUser()" :class="{'disabledButton':this.loading || this.tableLoading}" alt="create user"/>
            <language-switch ref="languageswitch" background="white" @selectPickerLanguage="selectPickerLanguage"/>
            <img v-on:click="toggleUserMenu()" class="user-logo" src="../../assets/user_header.svg" alt="toggle"/>
          </div>
        </div>
        <div v-if="this.showUserMenu">
          <div class="userMenuClass">
            <div class="userMenuContent" v-on:click="selfProfile()" >
              <div class="userName">{{ me.firstName }} {{ me.lastName }}</div>
              <div class="userPhone">{{ me.phone }}</div>
            </div>
            <div class="userMenuContent">
              <div v-on:click="openLink('privacy-policy')" class="infoLink spacedLink">{{ $t('HOME.PRIVACY_POLICY') }}</div>
              <div v-on:click="openLink('terms-of-use')" class="infoLink">{{ $t('HOME.TERMS_OF_USE') }}</div>
            </div>
            <div class="logoutButton" v-on:click="logout()">{{ $t('HOME.LOGOUT') }}</div>
          </div>
          <div v-if="this.showUserMenu === true" v-on:click="closeUserMenu()" class="clickableBg"></div>
        </div>
<!--         hide other things-->
        <div v-if="this.showLockGroupTab === false" class="information">
          <div class="block_save_button">
            <img class="info_icon" src="../../assets/info_icon.svg"/>
            <div v-if="this.currentTab === 'locks'" class="info_text" >
              {{ $t('HOME.INFO_TEXT') }}
            </div>
            <div v-if="this.currentTab === 'cards'" class="info_text" >
              {{ $t('HOME.INFO_TEXT_TRANSPONDERS') }}
            </div>
            <img class="icon_legenda first_icon" src="../../assets/key_icon.svg"/>
            <div class="info_legenda" >{{ $t('HOME.ACCESS') }}</div>
            <img v-if="this.currentTab === 'locks'" class="icon_legenda" src="../../assets/admin_icon.svg"/>
            <div v-if="this.currentTab === 'locks'" class="info_legenda" >{{ $t('HOME.ADMIN') }}</div>
            <img class="icon_legenda" src="../../assets/icon_web_accesses_no.svg"/>
            <div class="info_legenda" >{{ $t('HOME.NO_ACCESS') }}</div>
            <img class="icon_legenda_big" src="../../assets/icon_web_accesses_mixed.svg"/>
            <div class="info_legenda" >{{ $t('HOME.MIXED') }}</div>
            <span class="loader" v-if="this.tableLoading"></span>
          </div>
        </div>
        <div v-if="this.showLockGroupTab === false"  class="block_save_button second_block">
          <div class="page_selector_label">{{ $t('HOME.VIEW') }}</div>
          <div class="page_selector_space">
            <page-selector
                :currentTab="currentTab"
                :sortingByCompany="sortingByCompany"
                :selectorDisabled="this.listOfChanges.length > 0"
                :showTranspondersLink=true
                @tabSwitch="tabSwitch"
                @changeSortingMethod="changeSortingMethod"
                @changeSortingAndTabSwitch="changeSortingAndTabSwitch"
            />
          </div>
          <div v-if="this.showSearch()" class="group_selector_label">{{ $t('HOME.LOCK_GROUP') }}:</div>
          <div v-if="this.showSearch()" class="page_selector_space">
            <group-selector
                :currentLockGroup="this.currentLockGroup"
                :selectorDisabled="this.listOfChanges.length > 0"
                :locksGroups="this.allLockGroupsIHaveAccessTo"
                @selectGroup="selectGroup"
            />
          </div>
          <div v-if="this.showSearch()" class="search_field_label">
            {{ $t('HOME.USER_SEARCH') }}:
          </div>
          <div v-if="this.showSearch()" class="search_field">

            <input
                maxlength="32"
                :value="this.search_text"
                :disabled="this.listOfChanges.length > 0"
                class="search_field_input_class"
                v-bind:class="{selector_disabled:this.listOfChanges.length > 0}"
                @change="e => this.search_text = e.target.value"
                @blur="trigger_search"
                v-on:keyup.enter="trigger_search"
            >
          </div>
          <div v-if="this.search_text !=='' && this.showSearch() && this.listOfChanges.length === 0" class="search_field_action">
            <img @click="clearSearch" src="../../assets/cancel.svg"/>
          </div>
        </div>


      </div>

      <div v-if="this.currentTab === 'locks' && this.showLockGroupTab === false" class="content">
        <div class="col-12">
          <table class="table" v-if="this.users.length > 0 && this.locks.length > 0">
            <thead>
            <tr>
              <th class="firstChild" v-bind:class="{ menuIsOpenClass: menuIsOpen===true, menuIsClosedClass: menuIsOpen===false, menuIsLoadingClass: menuIsOpen==='loading' }">
                <div class="menuContent">
                  <div class="firstLabel">{{ $t('HOME.NAME') }}</div>
                  <div class="infoLabels">{{ $t('HOME.COMPANY') }}</div>
                  <div class="infoLabelsPhone">{{ $t('HOME.PHONE_NUMBER') }}</div>
                </div>
                <div v-on:click="toggleInfo" class="toggleButton" v-bind:class="{ rotateButton: (menuIsOpen===false || menuIsOpen==='loading')}">
                  <img src="../../assets/icon_arrow.svg"/>
                </div>
              </th>

              <th class="cellHeader cellGroup">
                <div class="cellNameContent rotate">
                  <div class="groupOfLocks cellName">
                    <div class="longName">{{this.allRoomsName()}}</div>
                  </div>
                </div>
              </th>

              <th v-for="lock in lockObjects" v-on:click="startEditLockMode(lock)"
                  :key="lock.id" class="cellHeader editableName"
                  :class="{'textSelected':effectedLock(lock.id), cellGroup : (lock.type === 'g')}">
                <div class="cellNameContent rotate">
                  <img class="cellNameImage" v-if="lock.battery_state && lock.battery_state !== 'OK'" src="../../assets/battery_low.svg"/>
                  <div v-bind:class="{groupOfLocks : (lock.type === 'g')}" class="cellName">
                    <div class="longName">{{lock.name}}</div>
                  </div>
                </div>
                <md-tooltip class="myTooltipClass" md-delay="500">
                  {{lock.name}}
                </md-tooltip>
              </th>
            </tr>
            </thead>
            <tbody v-if="!this.loading">

            <UserRow v-for="(user, index) in users"
                     :key="user.company + user.phone"
                     :user="user"
                     :index="index"
                     :userInForeground="userInForeground"
                     :lockObjects="lockObjects"
                     :lastAddedUserPhoneNumber="lastAddedUserPhoneNumber"
                     :users="users"
                     :loading="loading"
                     :tableLoading="tableLoading"
                     :tooltipText="tooltipText"
                     :sortingByCompany="sortingByCompany"
                     :locksGroupsFiltered="locksGroupsFiltered"
                     @updateListOfChanges="updateListOfChanges"
                     @openDeleteUserModal="openDeleteUserModal"
                     @startEditUserMode="startEditUserMode"
                     @toggleInfo="toggleInfo"
            />

            <tr v-if="this.tableLoading" class="loadingAnimation" :class="this.users.length % 2 !== 0 && 'evenRow'">
              <td class="caption">
                <div class="menuContent">
                  <span class="userImageClass">&nbsp;</span>
                  <span class="userNameClass">&nbsp;</span>
                  <span class="infoClass">&nbsp;</span>
                  <span class="infoClass">&nbsp;</span>
                </div>
              </td>
              <td v-if="lockObjects.length > 1" class="cell allRooms">
                <div class="singleCell">
                  <div class="wrapperIcon">
                    &nbsp;
                  </div>
                </div>
              </td>
              <td v-for="column in lockObjects.length" :key="column" class="cell">
                <div class="singleCell">
                  <div class="wrapperIcon">
                    &nbsp;
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="this.currentTab === 'cards' && this.showLockGroupTab === false" class="content">
        <div class="col-12">
          <table class="table" v-if="this.cards.length > 0" >
            <thead>
              <tr>
                <th class="firstChild" v-bind:class="{ menuIsOpenClass: menuIsOpen===true, menuIsClosedClass: menuIsOpen===false, menuIsLoadingClass: menuIsOpen==='loading' }">
                  <div class="menuContent">
                    <div class="firstLabel">{{ $t('HOME.OWNER') }}</div>
                    <div class="infoLabelOne">{{ $t('HOME.COMPANY') }}</div>
                    <div class="infoLabelTwo">{{ $t('HOME.CARD_NUMBER') }}</div>
                    <div class="infoLabels">{{ $t('HOME.EXPIRING_DATE') }}</div>
                  </div>
                  <div v-on:click="toggleInfo" class="toggleButtonTransponders" v-bind:class="{ rotateButton: (menuIsOpen===false || menuIsOpen==='loading')}">
                    <img src="../../assets/icon_arrow.svg"/>
                  </div>
                </th>

                <th v-if="lockObjects.length > 1" class="cellHeader">
                  <div class="cellNameContent rotate">
                    <div class="transponderFirstCell cellName">
                      <div class="longName">{{ $t('HOME.ALL_ROOMS') }}</div>
                    </div>
                  </div>
                </th>

                <th v-for="lock in lockObjects" v-on:click="startEditLockMode(lock)"  :key="lock.id" class="cellHeader editableName">
                  <div class="cellNameContent rotate">
                    <img class="cellNameImage" v-if="lock.battery_state && lock.battery_state !== 'OK'" src="../../assets/battery_low.svg"/>
                    <div class="cellName">
                      <div class="longName">{{lock.name}}</div>
                    </div>
                  </div>
                  <md-tooltip class="myTooltipClass" md-delay="500">
                    {{lock.name}}
                  </md-tooltip>
                </th>
              </tr>
            </thead>

            <tbody v-if="!this.loading">

            <CardRow v-for="(card, index) in cards"
                     :key="card.id"
                     :card="card"
                     :index="index"
                     :lockObjects="lockObjects"
                     :lastAddedUserPhoneNumber="lastAddedUserPhoneNumber"
                     :loading="loading"
                     :tableLoading="tableLoading"
                     :tooltipText="tooltipText"
                     @openDeleteCardModal="openDeleteCardModal"
                     @toggleInfo="toggleInfo"
            />

            </tbody>

          </table>
        </div>
      </div>

      <div v-if="this.cards.length < 1 && !this.loading && this.currentTab==='cards'" class="alert">
        <div>
          <div class="alert_title">{{ $t('HOME.ALERT.TITLE') }}</div>
          <div class="alert_content">{{ $t('HOME.ALERT.NO_USERS') }}</div>
          <div class="alert_content" v-html="$t('HOME.ALERT.INFO')" />
          <div class="disclaimer">
              <span class="disclaimer_icon">
                  <img src="../../assets/access.svg"/>
                  <div>{{ $t('HOME.ACCESS') }}</div>
              </span>
            <span class="disclaimer_icon">
                    <img src="../../assets/admin.svg"/>
                    <div>{{ $t('HOME.ADMIN') }}</div>
                </span>
            <span class="disclaimer_icon">
                    <img src="../../assets/no_access.svg"/>
                    <div>{{ $t('HOME.NO_ACCESS') }}</div>
                </span>
          </div>
        </div>
      </div>

      <div v-if="(this.users.length < 1 || this.locks.length < 1) && !this.loading && this.currentTab==='locks' && !this.showLockGroupTab" class="alert">
        <div>
          <div class="alert_title">{{ $t('HOME.ALERT.TITLE') }}</div>
          <div class="alert_content">{{ $t('HOME.ALERT.NO_USERS') }}</div>
          <div class="alert_content" v-html="$t('HOME.ALERT.INFO')" />
          <div class="disclaimer">
              <span class="disclaimer_icon">
                  <img src="../../assets/access.svg"/>
                  <div>{{ $t('HOME.ACCESS') }}</div>
              </span>
            <span class="disclaimer_icon">
                    <img src="../../assets/admin.svg"/>
                    <div>{{ $t('HOME.ADMIN') }}</div>
                </span>
            <span class="disclaimer_icon">
                    <img src="../../assets/no_access.svg"/>
                    <div>{{ $t('HOME.NO_ACCESS') }}</div>
                </span>
          </div>
        </div>
      </div>

      <div v-if="this.showLockGroupTab === true" >
        <lock-group-tab
            :locksGroups="allLockGroupsIHaveAccessTo"
            @addGroupToLocksGroups="addGroupToLocksGroups"
            @removeGroupToLocksGroups="removeGroupToLocksGroups"
            @sortLocksGroups="sortLocksGroups"
            @setNeedToUpdateLocalData="setNeedToUpdateLocalData"
        ></lock-group-tab>
      </div>

      <div v-if="showDeleteUserModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container modal-delete">

            <div class="modal-header">
              <img @click="closeModal" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $t('HOME.DELETE_USER.HEADER') }}
              </slot>
            </div>

            <div class="modal-body">
              <div class="new-user-info">
                <slot name="body">
                  {{deleteUserModalBody}}
                </slot>
              </div>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="action-modal">
                  <div @click="deleteUser" class="link-color action-link">
                    {{ $t('HOME.DELETE') }}
                  </div>
                  <div>
                  <img @click="closeModal" class="pointer save_user_button" :src="cancelButton()"/>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showDeleteCardModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container modal-delete">

            <div class="modal-header">
              <img @click="closeModal" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $t('HOME.DELETE_CARD.HEADER') }}
              </slot>
            </div>

            <div class="modal-body">
              <div class="new-user-info">
                <slot name="body">
                  <div>
                    {{deleteCardModalBody}}
                  </div>
                  <br>
                  <div>
                    {{deleteCardModalBodyAdd}}
                  </div>
                </slot>
              </div>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="action-modal">
                  <div @click="deleteCard" class="link-color action-link">
                    {{ $t('HOME.DELETE') }}
                  </div>
                  <div>
                    <img @click="closeModal" class="pointer save_user_button" :src="cancelButton()"/>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showDiscardAccessChangesModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container modal-delete">

            <div class="modal-header">
              <img @click="showDiscardAccessChangesModal = false" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $t('HOME.DISCARD_ACCESS_CHANGES.HEADER') }}
              </slot>
            </div>

            <div class="modal-body">
              <div class="new-user-info">
                <slot name="body">
                  {{ $tc('HOME.DISCARD_ACCESS_CHANGES.BODY', numberOfChanges) }}
                </slot>
              </div>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="action-modal">
                  <div @click="deleteChanges" class="link-color action-link">
                    {{ $t('HOME.DISCARD') }}
                  </div>
                  <div>
                    <img @click="closeModal" class="pointer save_user_button" :src="cancelButton()"/>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>


      <div v-if="showDiscardUserChangesModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <img @click="continueEditing" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $tc('HOME.DISCARD_CHANGES.HEADER') }}
              </slot>
            </div>

            <div class="modal-body">
              <div class="new-user-info">
                <slot name="body">
                  {{ $t('HOME.DISCARD_CHANGES.BODY') }}
                </slot>
              </div>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <img @click="closeModal" class="pointer save_user_button" :src="discardUserButton()"/>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showEditLockModal && !loading" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-protocol">
            <div class="modal-header">
              <img @click="closeEditLockModal" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $t('HOME.EDIT_LOCK.HEADER') }}
              </slot>
            </div>
            <div class="modal-body">

              <div class="battery_block">
                <div class="battery_title">{{ $t('HOME.EDIT_LOCK.BATTERY_STATUS') }}</div>

                <div v-if="this.lockInForeground.battery_state && this.lockInForeground.battery_state !== 'OK'" >
                  <img class="battery_image" src="../../assets/battery_low.svg"/>
                  <span>{{ $t('HOME.EDIT_LOCK.BATTERY_LOW') }}</span>
                </div>
                <div v-if="this.lockInForeground.battery_state && this.lockInForeground.battery_state === 'OK'" >
                  <img class="battery_image" src="../../assets/battery_ok.svg"/>
                  <span>{{ $t('HOME.EDIT_LOCK.BATTERY_OK') }}</span>
                </div>
                <div v-if="!this.lockInForeground.battery_state" >
                  <img class="battery_image" src="../../assets/battery_broken.svg"/>
                  <span>{{ $t('HOME.EDIT_LOCK.BATTERY_NOT_SET') }}</span>
                </div>

                <div class="last_check" v-if="this.lockInForeground.battery_last_reading && this.lockInForeground.battery_state">
                  <span>{{ $t('HOME.EDIT_LOCK.LAST_CHECKED') }}</span>
                  {{this.formatDateWithMoment(this.lockInForeground.battery_last_reading)}}
                </div>
              </div>

              <div class="lock-edit-flex">
                <div>
                  <div class="edit-lock-info">
                    <slot name="body">
                      {{ $t('HOME.EDIT_LOCK.BODY') }}
                    </slot>
                  </div>
                  <div class="change-name-content">
                    <div class="lock-name-field">
                      <md-field :md-counter="false">
                        <md-input
                            maxlength="32"
                            v-model="lockInForeground.name"
                        >
                        </md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="edit-lock-info">
                    <div class="select-lock-group">
                      <slot name="body">
                        {{ $t('HOME.LOCK_GROUP_SELECT.HEADER') }}
                      </slot>
                    </div>
                  </div>
                  <div class="change-name-content">
                    <div class="lock-name-field">

                      <md-field>
                        <md-select v-model="lockInForeground.lock_group_id">
                          <md-option value="0">{{ $t('HOME.NOTHING') }}</md-option>
                          <md-option v-for="group in this.allLockGroupsIHaveAccessTo" :key="group.id" :value="group.id">{{ lockGroupName(group.id) }}</md-option>
                        </md-select>
                      </md-field>

                    </div>
                  </div>
                </div>
                <div>
                  <img @click="saveLockDetails" :class="{'pointer':lockForegroundFieldComplete()}" class="save_user_button" :src="updateLockButton()"/>
                </div>

              </div>



              <div class="protocol-info">
                <slot name="body">
                  {{ $t('HOME.PROTOCOL.BODY') }}
                </slot>
              </div>

              <div class="lock-edit-flex">
                <div>
                  <div>{{ $t('HOME.START_DATE') }}</div>
                  <datepicker
                      v-model="startdate"
                      :language="pickerLanguage"
                      :disabled-dates="disabledStartDates"
                      @selected="startDateSelected"
                      input-class="picker-input-class"
                      calendar-class="picker-class"
                  />
                </div>

                <div>
                  <div>{{ $t('HOME.END_DATE') }}</div>
                  <datepicker
                      v-model="enddate"
                      :language="pickerLanguage"
                      :disabled-dates="disabledEndDates"
                      @selected="endDateSelected"
                      input-class="picker-input-class"
                      calendar-class="picker-class"
                  />
                </div>

                <div>
                  <img @click="applyDate" :class="{'pointer':isButtonEnabled()}" class="save_user_button" :src="applyButton()"/>
                </div>
              </div>


              <div>
                <div id="record-container">

                  <div v-for="(record, index) in this.filteredProtocol" :key="record.lock_timestamp">
                    <div class="label_container" v-if="showLabel(index)">
                      <div class="label_border"></div>
                      <div class="label_data">
                        <div class="label_data_text">{{dateForTheLabel(record.lock_timestamp)}}</div>
                      </div>
                    </div>
                    <div class="single-record" :class="{'single-record-border':recordBorder(index)}" v-if="record.channel === 'nfcpki' ">
                        <span class="record-image"><img src="../../assets/trasponder.svg"/></span>
                        <div class="single-record-element first-element">{{record.card_note}}</div>
                        <div class="single-record-element second-element">{{record.card_company}}</div>
                        <div class="single-record-element third-element">{{record.nfc_card_id}}</div>
                        <div class="single-record-element fourth-element">{{timeForProtocol(record.lock_timestamp)}}</div>
                    </div>
                    <div class="single-record" :class="{'single-record-border':recordBorder(index)}" v-else-if="record.channel === 'btlespp' ">
                        <span class="record-image "><img src="../../assets/user.svg"/></span>
                        <div class="single-record-element first-element">{{record.first_name}} {{record.last_name}}</div>
                        <div class="single-record-element second-element">{{record.company}}</div>
                        <div class="single-record-element third-element">{{record.phone}}</div>
                        <div class="single-record-element fourth-element">{{timeForProtocol(record.lock_timestamp)}}</div>
                    </div>
                  </div>
                </div>
                <div v-if="this.loadingProtocol" class="loadingAnimation">
                  <div class="wrapperIcon">
                    &nbsp;
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="showEditUserModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <img @click="closeEditUserMode" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $t('HOME.EDIT_USER.HEADER') }}
              </slot>
            </div>

            <div class="modal-body">
              <md-field :md-counter="false">
                <label>{{ $t('HOME.NEW_USER.FIRST_NAME') }}</label>
                <md-input
                    maxlength="32"
                    :value="userInForeground.first_name"
                    @change="e => userInForeground.first_name = e.target.value">
                </md-input>
              </md-field>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.NEW_USER.LAST_NAME') }}</label>
                <md-input
                    maxlength="32"
                    :value="userInForeground.last_name"
                    @change="e => userInForeground.last_name = e.target.value">
                </md-input>
              </md-field>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.NEW_USER.COMPANY') }}</label>
                <md-input
                    maxlength="32"
                    :value="userInForeground.company"
                    @change="e => userInForeground.company = e.target.value">
                </md-input>
              </md-field>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <img @click="saveUserDetails" :class="{'pointer':userForegroundFieldComplete()}" class="save_user_button" :src="updateUserButton()"/>
              </slot>
            </div>

          </div>
        </div>
      </div>


      <div v-if="showAddUserModal" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <img @click="closeModal" class="modal_close" src="../../assets/cancel.svg"/>
              <slot name="header">
                {{ $t('HOME.NEW_USER.HEADER') }}
              </slot>
            </div>

            <div class="modal-body">
              <div class="new-user-info">
                <slot name="body">
                  {{ $t('HOME.NEW_USER.INFO') }}
                </slot>
              </div>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.NEW_USER.FIRST_NAME') }}</label>
                <md-input
                    maxlength="32"
                    v-model="newUser.first_name"
                    >
                </md-input>
              </md-field>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.NEW_USER.LAST_NAME') }}</label>
                <md-input
                    maxlength="32"
                    v-model="newUser.last_name"
                    >
                </md-input>
              </md-field>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.NEW_USER.COMPANY') }}</label>
                <md-input
                    maxlength="32"
                    v-model="newUser.company"
                    >
                </md-input>
              </md-field>
              <md-field>
                <label>{{ $t('HOME.NEW_USER.PHONE_NUMBER') }}</label>
                <md-input :change="validatePhoneNumber(newUser.phone_nr)" v-on:keyup.enter="addUser" v-model="newUser.phone_nr"></md-input>
              </md-field>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <img @click="addUser" :class="{'pointer':phoneNumberIsValid === true}" class="save_user_button" :src="saveUserButton()"/>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <snackbar />
    </div>
    <div v-if="this.listOfChanges.length > 0 && !this.loading && !this.tableLoading" class="widgetUndo">
      <div class="widgetUndoContent">
        <div class="widgetUndoText">
          {{ $tc('HOME.NUMBER_OF_CHANGES', this.numberOfChanges) }}

        </div>
        <div>
          <img v-on:click="saveData()" class="pointer" :src="saveButton()"/>
        </div>
        <div v-on:click="openDiscardChangesModal()" class="widgetUndoText pointer link-color">
          {{ $t('HOME.DISCARD') }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import GoogleTagManagerService from "@/service/GoogleTagManagerService";

const GoogleFontsPlugin = require("google-fonts-webpack-plugin")

import SessionService from "../../service/SessionService";
import ApiService from "../../service/ApiService";
import Spinner from "../element/Spinner"
import UserRow from "../element/UserRow"
import CardRow from "../element/CardRow"
import Snackbar from "../element/Snackbar";
import LanguageSwitch from "../element/LanguageSwitch";
import PageSelector from "@/components/element/PageSelector";
import GroupSelector from "@/components/element/GroupSelector";
// import LockGroupList from "@/components/element/LockGroupList";
import LockGroupTab from "@/components/element/LockGroupTab";
import Datepicker from 'vuejs-datepicker';
import {en, de} from 'vuejs-datepicker/dist/locale'
import moment from 'moment';
import localization from 'moment/dist/locale/de';

export default {
  plugins: [
    new GoogleFontsPlugin({
      fonts: [
        { family: "Mulish" },
      ]
    })
  ],
  components: {
    LockGroupTab,
    PageSelector,
    GroupSelector,
    // LockGroupList,
    Snackbar,
    Spinner,
    LanguageSwitch,
    UserRow,
    CardRow,
    Datepicker
  },
  data () {
    return {
      pickerDe: de,
      pickerEn: en,
      pickerLanguage: de,
      startdate: new Date(),
      enddate: new Date(),
      disabledStartDates: {
        from: new Date()
      },
      disabledEndDates: {
        from: new Date(),
      },
      loading: true,
      loadingProtocol: false,
      tableLoading: true,
      menuIsOpen: "loading",
      showAddUserModal: false,
      showEditUserModal: false,
      showEditLockModal: false,
      showDeleteUserModal: false,
      showDeleteCardModal: false,
      showDiscardUserChangesModal: false,
      showDiscardAccessChangesModal: false,
      userInForeground: null,
      cardInForeground: null,
      lockInForeground: null,
      showUserMenu: false,
      deleteUserModalBody: '',
      deleteCardModalBody: '',
      deleteCardModalBodyAdd: '',
      users: [],
      locks: [],
      locksGroups: [],
      locksGroupsFiltered: [],
      rootLockGroupIds: [],
      allLockGroupsIHaveAccessTo: [],
      cards: [],
      tooltipText: '',
      listOfChanges: [],
      numberOfChanges: 0,
      copyOfUsers: null,
      phoneNumberIsValid: false,
      phoneValidationRegex: /^\+(?:[0-9] ?){6,14}[0-9]$/,
      lastAddedUserPhoneNumber: false,
      newUser: {
        phone_nr: null,
        first_name: null,
        last_name: null,
        company: null
      },
      me: {
        firstName: null,
        lastName: null,
        phone: null
      },
      sortingByCompany: this.sortingByCompanyCookie,
      currentTab: this.currentTabCookie,
      showLockGroupTab: false,
      currentLockGroup: null,
      currentProtocol: [],
      filteredProtocol: [],
      tempDate: '',
      lastDates: '',
      cursor_protocol: null,
      cursor_user: null,
      cursor_card: null,
      search_text: "",
      current_search: "",
      complete_lock_list: false,
      needToUpdateLocalData: false,
    }
  },
  mounted(){
    this.currentTab = this.currentTabCookie
    this.startdate = new Date(this.startdate.setMonth(this.startdate.getMonth() - 6));
    this.enddate = new Date(this.enddate.setHours(this.enddate.getHours() - 1));
    this.disabledEndDates.to = this.startdate
  },
  name: 'Home',
  created() {
    this.sortingByCompany = this.sortingByCompanyCookie
    this.currentLockGroup = JSON.parse(this.currentLockGroupCookie)

    this.selectPickerLanguage();

    if (this.$i18n.locale === 'de') {
      moment.updateLocale('de', localization);
    }
    ApiService.getMe().then(result => {
      this.me.firstName = result.me.first_name;
      this.me.lastName = result.me.last_name;
      this.me.phone = result.me.phone;
      this.reload(true)
      document.addEventListener("scroll", this.bodyScrolled);
      window.addEventListener("resize", this.bodyScrolled);
    }).catch(error => {
      console.log("ERROR:", error);
    })
  },
  methods: {
    reload(complete){
      if(this.currentTab === 'locks'){
        this.loading = true;
        this.tableLoading = false;
        this.users = [];
        this.cards = [];
        this.copyOfUsers = []
        this.cursor_user = null
        this.cursor_card = null
        if (complete){
          this.getAllLockGroupsIHaveAccessTo()
        } else {
          this.getListOfAccessRights()
        }
      } else {
        if (complete){
          this.getCards()
        } else {
          this.getListOfCardRights();
        }
      }
    },

    showSearch(){
      if(this.currentTab && this.currentTab === 'locks') {
        return true
      }
      return false
    },

    showLabel(index){
      // show the day only when the previous day is different
      if (index === 0){return true}
      let currentDate = this.filteredProtocol[index].lock_timestamp.substring(0,10)
      let prevCurrentDate = this.filteredProtocol[index-1].lock_timestamp.substring(0,10)
      if (currentDate !== prevCurrentDate){
        return true
      }
      return false
    },
    recordBorder(index){
      // don't return the border bottom when there is a label next or in case of last element
      if (index === (this.filteredProtocol.length-1)){return false}
      let currentDate = this.filteredProtocol[index].lock_timestamp.substring(0,10)
      let nextCurrentDate = this.filteredProtocol[index+1].lock_timestamp.substring(0,10)
      if (currentDate !== nextCurrentDate) {return false}
      return true
    },
    moment(){
      return moment()
    },
    applyDate: function () {
      if(this.isButtonEnabled()) {
        let date1 = new Date(this.startdate).getTime();
        let date2 = new Date(this.enddate).getTime();
        if(date2 < date1){
          this.enddate = new Date(this.startdate)
        }
        this.getProtocolFromBe(this.lockInForeground)
      }
    },
    startDateSelected: function () {
      this.$nextTick(() => {
        this.disabledEndDates = {to: this.startdate}
      })
    },
    endDateSelected: function () {
      this.$nextTick(() => {
        this.disabledStartDates = {from: this.enddate}
      })
    },
    selectPickerLanguage(){
      if (this.$i18n.locale === 'de') {
        moment.updateLocale('de', localization);
        this.pickerLanguage = this.pickerDe
      }
      if (this.$i18n.locale === 'en') {
        moment.updateLocale('en', {});
        this.pickerLanguage = this.pickerEn
      }
    },
    tabSwitch(to){
      if (this.currentTab !== to){
        if (this.currentTab === 'locks') {
          this.currentTab = 'cards'
        } else if (this.currentTab === 'cards'){
          this.currentTab = 'locks'
        }
        this.currentTabCookie = this.currentTab
        this.$store.dispatch('updateCurrentTab', this.currentTab);
        this.reload(true)
      }
    },
    lockGroupSwitch(change){
      //change false go to home, true go to group tab
      if(!change){
        this.checkBodyScrollAtSwitch()
      }
      if(!change && this.needToUpdateLocalData){
        this.needToUpdateLocalData = false
        this.reload(true)
      }
      this.showLockGroupTab = change
    },
    openDiscardChangesModal(){
      this.showDiscardAccessChangesModal = true
    },
    changeSortingMethod(){
      this.sortingByCompany = !this.sortingByCompany;
      this.sortingByCompanyCookie = this.sortingByCompany;
      this.$store.dispatch('updateSortingByCompany', this.sortingByCompany);
      this.lastAddedUserPhoneNumber = false;
      this.reload()
    },
    changeSortingAndTabSwitch(sortByCompany){
      if (sortByCompany !== this.sortingByCompany){
        this.sortingByCompany = sortByCompany;
        this.$store.dispatch('updateSortingByCompany', this.sortingByCompany);
        this.lastAddedUserPhoneNumber = false;
      }
      this.currentTab = 'locks'
      this.currentTabCookie = this.currentTab
      this.$store.dispatch('updateCurrentTab', this.currentTab);
      this.reload(true)
    },
    scrollToTheNewElement(){
      setTimeout(()=>{
        let element = document.getElementById("newElement");
        if(element){
          let elementPosition = element.getBoundingClientRect().top;
          let offsetPosition = elementPosition + window.pageYOffset - 300;
          if (element) {
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            });
          }
        }
      },1)
    },
    backgroundRowClass(index, phone) {
      if (index % 2 !== 0) {
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightEvenRow'
        }
        return 'evenRow'
      } else {
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightRow'
        }
      }
    },
    openLink(target) {
      switch (target) {
        case 'privacy-policy':
          window.open('https://akii.app/datenschutzerklaerung-web-app/', '_blank');
          break;
        case 'terms-of-use':
          window.open('https://akii.app/nutzungsbedingungen', '_blank');
          break;
      }
    },

    isStringValid(inputtxt) {
      return inputtxt && inputtxt.length > 2
    },

    isThisNumberValid(inputtxt) {
      return (inputtxt && inputtxt !== this.me.phone && inputtxt.match(this.phoneValidationRegex))
    },

    validatePhoneNumber(inputtxt) {
      if (this.isThisNumberValid(inputtxt)) {
        this.phoneNumberIsValid = true
      } else {
        this.phoneNumberIsValid = false
      }
    },

    openAddUserModal: function () {
      if(!this.loading && !this.tableLoading) {
        if (this.numberOfChanges > 0) {
          this.showDiscardAccessChangesModal = true;
        } else {
          GoogleTagManagerService.pushToDataLayer({event: 'open-add-user-modal'});
          // if (this.currentTab !== 'locks'){
          //   this.tabSwitch()
          // }
          this.showAddUserModal = true;
          this.showDeleteUserModal = false;
          this.showUserMenu = false
          this.$refs.languageswitch.closeLanguageMenu();
        }
      }
    },
    checkForEditStatus: function () {
      if (this.userInForeground !== null){
        let user = this.copyOfUsers.find(el => el.id === this.userInForeground.id);
        if (user.first_name !== this.userInForeground.first_name || user.last_name !== this.userInForeground.last_name || user.company !== this.userInForeground.company) {
          return true
        }
      }
      return false
    },
    startEditUserMode: function (user) {
      if (this.numberOfChanges > 0) {
        this.showDiscardAccessChangesModal = true;
      } else {
        this.lastAddedUserPhoneNumber = false;
        this.userInForeground = JSON.parse(JSON.stringify(user));
        this.showEditUserModal = true;
      }
    },
    dateForTheLabel: function (date){
      return moment.utc(date).local().format("ddd DD MMM YYYY");
    },
    timeForProtocol: function (time){
      let recordTime = moment.utc(time).local().format("HH:mm")
      if(this.$i18n.locale === 'de'){
        recordTime = recordTime + ' Uhr'
      }
      return recordTime;
    },

    getProtocolFromBe: function (lock) {
        let startDate = moment(this.startdate).format("YYYY-MM-DD HH:mm:ss");
        let endDate = moment(this.enddate).format("YYYY-MM-DD HH:mm:ss");
        if (!this.cursor_protocol){
          this.loading = true;
        } else {
          this.loadingProtocol = true;
        }
        let cursors = this.cursor_protocol
        ApiService.getProtocol(lock.secured_object_id, startDate, endDate, cursors).then(result => {
          this.lastAddedUserPhoneNumber = false;
          this.showEditLockModal = true;
          this.currentProtocol = this.currentProtocol.concat(result.me.boxesICanAccess[0].lock_activations.data)
          // this.currentProtocol = result.me.boxesICanAccess[0].lock_activations.data
          this.updateProtocol()
          if (result.me.boxesICanAccess[0].lock_activations.cursors
              && result.me.boxesICanAccess[0].lock_activations.cursors.next !== null) {
              this.cursor_protocol = [{
                name: result.me.boxesICanAccess[0].lock_activations.cursors.name,
                value: result.me.boxesICanAccess[0].lock_activations.cursors.next
              }]
          } else {
            this.cursor_protocol = null
          }
        }).catch(error => {
          this.currentProtocol = []
          this.filteredProtocol = []
          console.log("ERROR:", error);
        }).finally(()=>{
          this.lastDates = lock.secured_object_id+this.startdate+this.enddate
          this.showEditLockModal = true;
          this.loading = false;
          this.loadingProtocol = false;
          this.startCheckScrolling()
        })
    },
    startCheckScrolling: function(){
      setTimeout(()=> {
            document.getElementById("record-container").addEventListener("scroll", this.protocolScrolled, false);
          }
          ,500);
    },
    protocolScrolled: function(){
      const ele = document.getElementById("record-container")
      if((ele.scrollHeight - ele.clientHeight) - ele.scrollTop < 3){
        ele.removeEventListener("scroll", this.protocolScrolled)
        if(this.cursor_protocol){
          this.getProtocolFromBe(this.lockInForeground);
        }
      }
    },
    startEditLockMode: function (lock){
      this.lockInForeground = JSON.parse(JSON.stringify(lock));
      if(!this.lockInForeground.lock_group_id){
        this.lockInForeground.lock_group_id = 0
      }
      if(!lock.secured_object_id){
        this.selectGroup(lock)
      } else {
        if(this.lastDates !== (lock.secured_object_id+this.startdate+this.enddate)){
          this.currentProtocol = []
          this.filteredProtocol = []
          this.cursor_protocol = null
          this.getProtocolFromBe(lock);
        } else {
          this.showEditLockModal = true;
          this.startCheckScrolling()
        }
      }
    },
    allRoomsName(){
      if(this.currentLockGroup){
        return this.currentLockGroup.name
      }
      return this.$i18n.t('HOME.ALL_ROOMS')
    },
    selectGroup(group){
      if(!group){
        this.currentLockGroup = null
      } else {
        if(group.id.charAt(0) === 'g'){
          group.id = group.id.substring(1)
        }
        this.currentLockGroup = group
      }
      this.currentLockGroupCookie = this.currentLockGroup
      this.$store.dispatch('updateCurrentLockGroup', JSON.stringify(this.currentLockGroup));
      this.reload(true)
    },

    updateProtocol: function (){
      let newProtocol = []
      this.currentProtocol.forEach(record => {
        let dateStart = new Date(this.startdate.setHours(0,0,0,0)).getTime();
        let dateEnd = new Date(this.enddate.setHours(0,0,0,0)).getTime();
        let dateProtocol = new Date(new Date(record.lock_timestamp).setHours(0,0,0,0)).getTime();
        if(dateProtocol >= dateStart && dateProtocol <= dateEnd){
          newProtocol.push(record)
        }
      })

      this.filteredProtocol = newProtocol;
    },
    closeEditUserMode: function(){
      if (this.checkForEditStatus()){
        this.showEditUserModal = false;
        this.showDiscardUserChangesModal = true;
      } else {
        this.showEditUserModal = false;
      }
    },
    checkIfUserDataAreValid(user){
      return (user.first_name !== "" && user.last_name !== "" && user.company !== "")
    },
    continueEditing(){
      this.showEditUserModal = true;
      this.showDiscardUserChangesModal = false;
    },
    lockGroupName: function(id){
      let group = this.allLockGroupsIHaveAccessTo.find(l => l.id === id)
      if (group && group.name) {
        return group.name
      }
      return ''
    },
    addGroupToLocksGroups: function(group){
      this.allLockGroupsIHaveAccessTo.push(group)
      this.sortLocksGroups()
    },
    removeGroupToLocksGroups: function(groupId){
      this.allLockGroupsIHaveAccessTo = this.allLockGroupsIHaveAccessTo.filter(group => group.id !== groupId);
    },
    sortLocksGroups: function (){
      this.allLockGroupsIHaveAccessTo.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
    },
    setNeedToUpdateLocalData: function (){
      this.needToUpdateLocalData = true
    },

    saveLockDetails: function() {
      if(!this.lockForegroundFieldComplete()){
        return
      }
      this.lockInForeground.name = this.lockInForeground.name.trim();
      let lock = this.locks.find(l => l.id === this.lockInForeground.id)
      if (lock.name === this.lockInForeground.name && lock.lock_group_id === this.lockInForeground.lock_group_id){
        this.closeModal();
        return
      }
      this.closeModal();
      this.loading = true;
      ApiService.changeLockName(this.lockInForeground).then(() => {
        lock.name = this.lockInForeground.name;
        lock.lock_group_id = this.lockInForeground.lock_group_id
      }).catch((error) => {
        this.$root.$emit('SHOW_SNACKBAR', {
          error: error,
          message: this.$i18n.t('HOME.ERROR.SAVE_FAILED')
        })
      }).finally(() => {
        this.reload(true)
      })
    },
    saveUserDetails: function () {
      if (!this.userForegroundFieldComplete()){
        return;
      }
      this.closeModal()
      this.userInForeground.first_name = this.userInForeground.first_name.trim();
      this.userInForeground.last_name = this.userInForeground.last_name.trim();
      this.userInForeground.company = this.userInForeground.company.trim();
      let user = this.userInForeground;
      if (this.checkIfUserDataAreValid(user)){
        let userInUsers = this.users.find(el => el.id === user.id);
        if (userInUsers.last_name === user.last_name && userInUsers.first_name === user.first_name && userInUsers.company === user.company) {
          this.userInForeground = null;
          return
        }

        this.loading = true;
        this.showEditUserModal = false;

        ApiService.updateUser(user).then(() => {

          let userInUsers = this.users.find(el => el.id === user.id);
          userInUsers.name = this.parse_name(user);
          userInUsers.first_name = user.first_name;
          userInUsers.last_name = user.last_name;
          userInUsers.company = user.company;

          user.name = this.parse_name(user);

          this.lastAddedUserPhoneNumber = JSON.parse(JSON.stringify(user.phone));

          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.CHANGES_SAVED')
          })

          //update copy of users
          let userCopy = this.copyOfUsers.find(el => el.id === this.userInForeground.id);
          userCopy.first_name = this.userInForeground.first_name.toString();
          userCopy.last_name = this.userInForeground.last_name.toString();
          userCopy.company = this.userInForeground.company.toString();

        }).catch((error)=>{
          this.showEditUserModal = true;
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.SAVE_FAILED')
          })
        }).finally(()=>{
          this.userInForeground = null;
          this.loading = false;
          this.scrollToTheNewElement();
        })
      } else {
        this.$root.$emit('SHOW_SNACKBAR', {
          error: true,
          message: this.$i18n.t('HOME.MESSAGES.MISSING_FIELDS')
        })
      }

    },
    openDeleteCardModal: function (card){
      this.deleteCardModalBody = this.$i18n.t('HOME.DELETE_CARD.BODY', {
        cardid: card.nfc_card_id,
      });
      this.deleteCardModalBodyAdd = this.$i18n.t('HOME.DELETE_CARD.BODY_ADD', {
        cardid: card.nfc_card_id,
      });
      this.cardInForeground = card;
      this.showDeleteCardModal = true;
      this.showUserMenu = false
      this.$refs.languageswitch.closeLanguageMenu();
    },
    openDeleteUserModal: function (user) {
      if (this.numberOfChanges > 0) {
        this.showDiscardAccessChangesModal = true;
      } else {
        this.userInForeground = user;
        this.deleteUserModalBody = this.$i18n.t('HOME.DELETE_USER.BODY', {
          username: user.name,
        })
        this.showDeleteUserModal = true;
        this.showAddUserModal = false;
        this.showUserMenu = false
        this.$refs.languageswitch.closeLanguageMenu();
      }
    },
    toggleUserMenu: function () {
      this.showUserMenu = !this.showUserMenu
      this.$refs.languageswitch.closeLanguageMenu();
    },
    clearSearch: function () {
      this.search_text = ""
      if(this.current_search !==""){
        if(this.isStringValid){
          this.current_search = ""
          this.reload();
        } else {
          this.current_search = ""
        }
      }
    },
    trigger_search: function () {
      this.search_text = this.search_text.trim();
      if(this.search_text !== this.current_search) {
        if(this.isStringValid(this.search_text) || this.search_text === ''){
          this.reload();
          this.current_search = this.search_text
        }
      }
    },
    closeUserMenu: function () {
      this.showUserMenu = false;
    },
    deleteChanges: function () {
      this.reload();
      this.listOfChanges = [];
      this.numberOfChanges = 0;
      this.closeModal()
    },
    closeEditLockModal: function(){
      let lock = this.locks.find(l => l.id === this.lockInForeground.id)
      this.lockInForeground.name = lock.name
      this.lockInForeground.lock_group_id = lock.lock_group_id
      this.closeModal()
    },
    closeModal: function () {
      this.showAddUserModal = false;
      this.showDeleteUserModal = false;
      this.showDeleteCardModal = false;
      this.showDiscardUserChangesModal = false;
      this.showDiscardAccessChangesModal = false;
      this.showEditUserModal = false;
      this.showEditLockModal = false;
    },

    getCards(){
      this.loading = true;
      ApiService.getBoxesICanAccess().then(result => {
        this.locks = result.me.boxesICanAccess;
        this.getListOfCardRights()
      }).catch(()=>{
            this.loading = false;
          }
      )
    },

    getListOfCardRights: function(){
      if (!this.cursor_card){
        this.loading = true;
      } else {
        this.tableLoading = true;
      }
      let cursors = this.cursor_card

      ApiService.getListOfCards(cursors).then(result => {
        let idSet = new Set(this.cards.map(item => item.id));
        let newGroupOfCards = result.me.cardsICanAccessWithCursor.data.filter(item => !idSet.has(item.id));

        this.cards = this.cards.concat(newGroupOfCards);

        this.cursor_card = result.me.cardsICanAccessWithCursor.cursors.next || null
      }).catch((error)=>{
        console.log(error)
      }).finally(()=>{
            this.loading = false;
            this.tableLoading = false;
          }
      )
    },

    getListOfAccessRights: function(){
        if (!this.cursor_user){
          this.loading = true;
        } else {
          this.tableLoading = true;
        }
        let cursors = this.cursor_user

        let sortingBy = "last_name"

        if (this.sortingByCompany) {
          sortingBy = "company"
        }

        let stringToSearchFor = '';
        if(this.isStringValid(this.search_text)){
          stringToSearchFor = this.search_text
        }

        let properGroup = null
        if(this.currentLockGroup) {
          if(this.currentLockGroup.id.charAt(0) === 'g'){
            properGroup = this.allLockGroupsIHaveAccessTo.find(el=>el.id === this.currentLockGroup.id.slice(1))
          } else {
            properGroup = this.allLockGroupsIHaveAccessTo.find(el=>el.id === this.currentLockGroup.id)
          }
        }

        ApiService.getListOfAccessRights(cursors, sortingBy, stringToSearchFor, properGroup, JSON.stringify(this.rootLockGroupIds)).then(result => {

          this.cursor_user = result.boxAndAccessList.cursors.next || null

          let idSet = new Set(this.users.map(item => item.id));
          let newGroupOfUsers = result.boxAndAccessList.userAccessList.filter(item => !idSet.has(item.id));

          this.users = this.users.concat(newGroupOfUsers);

          this.users.forEach(user => {
            if(user.locks){
              user.locks.forEach(lock => {
                lock.can_access = true
              })
            }
          })

          this.copyOfUsers = JSON.parse(JSON.stringify(this.users));
          this.parseUsersAtFirst()

          this.loading = false;
          this.tableLoading = false;

          setTimeout(()=> {
                this.checkBodyScrollingAndCallForItems()
              }
              ,500);
        }).catch(()=>{
          this.loading = false;
          this.tableLoading = false;
        });
    },

    bodyScrolled() {
      this.checkBodyScrollingAndCallForItems()
    },

    checkBodyScrollingAndCallForItems() {
      if(this.cursor_user && this.tableLoading == false && this.currentTab === 'locks' && !this.showLockGroupTab) {
        if ((document.body.scrollHeight - (window.scrollY + window.innerHeight)) < 20 ) {
          this.getListOfAccessRights()
        }
      }
      if(this.cursor_card && this.tableLoading == false && this.currentTab === 'cards' && !this.showLockGroupTab) {
        if ((document.body.scrollHeight - (window.scrollY + window.innerHeight)) < 20 ) {
          this.getListOfCardRights()
        }
      }
    },
    //prevent this call when it is not needed
    checkBodyScrollAtSwitch(){
      if (this.cursor_user && (document.body.scrollHeight - (window.scrollY + window.innerHeight)) < 20 ) {
        this.getListOfAccessRights()
      }
    },

    getAllLockGroupsIHaveAccessTo(mode){
      ApiService.getAllLockGroupsIHaveAccessTo().then(result => {

        this.allLockGroupsIHaveAccessTo = result.allLockGroupsIHaveAccessTo
        this.sortLocksGroups()

        if(this.currentLockGroup !== null){
          let newCurrentLockGroup = this.allLockGroupsIHaveAccessTo.find(el=>el.id === this.currentLockGroup.id)
          if(newCurrentLockGroup){
            this.currentLockGroup = this.allLockGroupsIHaveAccessTo.find(el=>el.id === this.currentLockGroup.id)
          } else {
            this.currentLockGroup = null
            this.currentLockGroupCookie = this.currentLockGroup
            this.$store.dispatch('updateCurrentLockGroup', JSON.stringify(this.currentLockGroup));
          }
        }
        if(mode === 'cards'){
          this.getCards();
        } else {
          this.getBoxAndLockGroupsData();
        }
      }).catch((error) => {
        this.loading = false;
        this.tableLoading = false;
        console.log(error)
      })
    },

    getBoxAndLockGroupsData() {
      let properGroup = null
      if(this.currentLockGroup) {
        if(this.currentLockGroup.id.charAt(0) === 'g'){
          properGroup = this.allLockGroupsIHaveAccessTo.find(el=>el.id === this.currentLockGroup.id.slice(1))
        } else {
          properGroup = this.allLockGroupsIHaveAccessTo.find(el=>el.id === this.currentLockGroup.id)
        }
      }

      ApiService.getBoxAndLockGroups(properGroup).then(result => {

        this.locksGroupsFiltered = result.boxesICanManage.lockGroupsICanUse || [];
        this.locksGroupsFiltered.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
        this.rootLockGroupIds = [];
        //differentiate id
        this.locksGroupsFiltered.forEach((el)=>{
          this.rootLockGroupIds.push(el.id)
          el.type = 'g'
          el.lockGroupId = el.id
          el.id = 'g'+el.id
        })

        this.locks = result.boxesICanManage.boxes.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))

        //add groups at the beginning of locks array
        this.locks = this.locksGroupsFiltered.concat(this.locks)

        if(this.locks.length > 0){
          this.locks.forEach((l)=>{
            if(l.lock_group_id && l.lock_group_id === null){
              l.lock_group_id = "0"
            }
          })
          this.getListOfAccessRights()
        } else {
          // if a group is selected and there are no locks, unselect the group and check for locks at level zero
          if(this.currentLockGroup) {
            this.selectGroup(); //remove the group from the cookies and reload
          } else {
            this.loading = false;
            this.tableLoading = false;
          }
        }
      }).catch((error) => {
        this.selectGroup(); //remove the group from the cookies
        this.loading = false;
        this.tableLoading = false;
        try {
          if (error.response.errors[0].message === 'Unauthenticated.') {
            SessionService.deleteSession();
            this.$router.push({ name: 'login-view' });
          }
        } catch(e) {
          throw error;
        }
      })





    },
    parseUsersAtFirst: function () {
      // removes users of type company
      this.users = this.users.filter((u)=>{return u.type !== 'company'})
      if(this.sortingByCompany){
        let newUsersArray = [];
        let lastCompany = '';

        this.users.forEach(usr => {
          if(usr.company !== lastCompany){
            lastCompany = usr.company
            newUsersArray.push({'company':usr.company,'type':'company','name':'','phone':''})
          }
          newUsersArray.push(usr)
        })

        this.users = newUsersArray;
      }
    },
    saveData: function () {
      this.showUserMenu = false;
      this.lastAddedUserPhoneNumber = false;
      if (this.listOfChanges.length > 0 && !this.loading && !this.tableLoading) {
        this.loading = true;
        ApiService.updateAccessList(this.listOfChanges).then(() => {
          this.users = [];
          this.listOfChanges = [];
          this.numberOfChanges = 0;
          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.CHANGES_SAVED')
          })
          GoogleTagManagerService.pushToDataLayer({event: 'save-changes-success'});
          this.reload();
        }).catch(error => {
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.UPDATE_ACCESS_FAILED')
          })
          GoogleTagManagerService.pushToDataLayer({event: 'save-changes-failed'});
          this.loading = false
        })
      }
    },
    removeUserFromTheBoard: function (){
      // with this function there is no need to reload the new list of users
      this.users = this.users.filter((item)=>{return item.id !== this.userInForeground.id})
      this.copyOfUsers = this.copyOfUsers.filter((item)=>{return item.id !== this.userInForeground.id})
      this.userInForeground = null;
    },
    deleteCard: function (){
      this.closeModal()
    },
    deleteUser: function () {
      this.closeModal();
      this.loading = true;
      ApiService.deleteUser(this.userInForeground.id).then((response) => {
        if (response && response.removeUserFromLocksAndContacts === 'SUCCESS') {
          this.removeUserFromTheBoard();
          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.USER_DELETED')
          })
        } else {
          this.$root.$emit('SHOW_SNACKBAR', {
            error: response,
            message: this.$i18n.t('HOME.ERROR.' + response.removeUserFromLocksAndContacts)
          })
        }
      }).catch(error => {
        this.$root.$emit('SHOW_SNACKBAR', {
          error: error,
          message: this.$i18n.t('HOME.ERROR.DELETING_USER_FAILED')
        })
      }).finally(()=>{
        this.loading = false;
      })
    },

    addUser: function () {
      this.lastAddedUserPhoneNumber = false;

      if (this.phoneNumberIsValid === false) {
        return
      }

      this.loading = true;
      ApiService.addUser(this.newUser).then(() => {
        this.lastAddedUserPhoneNumber = JSON.parse(JSON.stringify(this.newUser.phone_nr));
        this.search_text = this.newUser.phone_nr
        this.newUser = {
          phone_nr: null,
          first_name: null,
          last_name: null,
          company: null
        };
        this.$root.$emit('SHOW_SNACKBAR', {
          message: this.$i18n.t('HOME.MESSAGES.CHANGES_SAVED')
        })
        this.listOfChanges = [];
        this.numberOfChanges = 0;
        GoogleTagManagerService.pushToDataLayer({event: 'save-user-success'});
        if(this.currentTab === 'cards'){
          this.currentTab = 'locks'
        } else {
          this.showLockGroupTab = false
        }
        this.trigger_search()
      }).catch(error => {
        this.loading = false
        console.log("error ", JSON.stringify(error))
        if (error.response.errors[0].message === 'Error adding user'
            && error.response.errors[0].extensions.reason === 'A user cannot add herself'
        ) {
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.ERROR_ADDING_YOURSELF')
          })
        } else {
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.ADDING_USER_FAILED')
          })
        }
        GoogleTagManagerService.pushToDataLayer({event: 'save-user-failed'});
      })

      this.showAddUserModal = false;
    },
    buttonAddUser: function (){
      if (this.$i18n.locale === 'de') {
        return require("../../assets/add_user_button_de.svg");
      }
      return require("../../assets/add_user_button.svg");
    },
    saveButton: function () {
      if (this.listOfChanges.length > 0 && !this.loading && !this.tableLoading) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    userForegroundFieldComplete: function () {
      return (this.userInForeground.first_name.trim().length > 0 && this.userInForeground.last_name.trim().length > 0 && this.userInForeground.company.trim().length > 0 )
    },
    lockForegroundFieldComplete: function () {
      if(this.lockInForeground.name.trim().length < 1){
        return false
      }
      let lock = this.locks.find(l => l.id === this.lockInForeground.id)
      if (lock.name === this.lockInForeground.name && lock.lock_group_id === this.lockInForeground.lock_group_id){
        return false
      }
      return true
    },
    updateLockButton: function(){
      if (this.lockForegroundFieldComplete()) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    updateUserButton: function () {
      if (this.userForegroundFieldComplete()) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    saveUserButton: function () {
      if (this.phoneNumberIsValid === true) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    deleteUserButton: function () {
      if (this.$i18n.locale === 'de') {
        return require("../../assets/delete_button_de.svg");
      }
      return require("../../assets/delete_button.svg");
    },
    cancelButton: function () {
      if (this.$i18n.locale === 'de') {
        return require("../../assets/cancel_button_de.svg");
      }
      return require("../../assets/cancel_button.svg");
    },
    isButtonEnabled: function () {
      return (this.lockInForeground.secured_object_id+this.startdate+this.enddate !== this.lastDates)
    },
    applyButton: function () {
      if (this.isButtonEnabled()) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/apply_button_enabled_de.svg");
        }
        return require("../../assets/apply_button_enabled_en.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/apply_button_disabled_de.svg");
      }
      return require("../../assets/apply_button_disabled_en.svg");
    },
    discardUserButton: function () {
      if (this.$i18n.locale === 'de') {
        return require("../../assets/discard_button_de.svg");
      }
      return require("../../assets/discard_button.svg");
    },

    effectedLock: function (lockId) {
      let effected = false;
      this.listOfChanges.forEach(el => {
        if (el.locks.find(l => l.id === lockId)) {
          effected = true
        }
      });
      return effected
    },
    toggleInfo: function() {
      this.showUserMenu = false;
      if (this.menuIsOpen === 'loading') {
        this.menuIsOpen = true;
        GoogleTagManagerService.pushToDataLayer({event: 'show-info'});
      } else {
        this.menuIsOpen = !this.menuIsOpen;
        GoogleTagManagerService.pushToDataLayer({event: 'hide-info'});
      }
    },

    updateListOfChanges: function(change){
      let currentChangesForSingleUser = this.listOfChanges.find(el => el.id === change.id);
      if (currentChangesForSingleUser === undefined) {
        let newRecord = {
          "id":change.id,
          "locks":[JSON.parse(JSON.stringify(change.lock))]
        };

        let user = this.copyOfUsers.find(el => el.id === change.id);
        let lock = user.locks.find(l => l.id === change.lock.id);

        if ((lock === undefined && !change.lock.can_access) || (lock !== undefined && change.lock.can_access === lock.can_access && change.lock.can_manage === lock.can_manage)) {
          // avoid turn on element on allrooms click button when they are set as they already are
        } else {
          this.listOfChanges.push(newRecord)
        }

      } else {
        let changeLock = currentChangesForSingleUser.locks.find(el => el.id === change.lock.id);
        if (this.changeIsEqualInOriginalData(change)) {
          this.removeFromListOfChanges(change.id, change.lock.id)
        } else {
          if (changeLock === undefined) {
            currentChangesForSingleUser.locks.push(change.lock)
          } else {
            if (changeLock.can_manage === undefined || (changeLock.can_access === false && changeLock.can_manage === false)) {
              changeLock.can_access = true;
              changeLock.can_manage = false;
            } else if (changeLock.can_access === true && changeLock.can_manage === false) {
              changeLock.can_access = true;
              changeLock.can_manage = true;
            } else if (changeLock.can_access === true && changeLock.can_manage === true) {
              changeLock.can_access = false;
              changeLock.can_manage = false;
            }
          }
        }
      }
      let changes = 0;
      if (this.listOfChanges.length > 0) {
        changes = this.listOfChanges.length
        this.listOfChanges.forEach(e=>{
          if (e.locks && e.locks.length > 1) {
            changes = changes + (e.locks.length - 1)
          }
        })
        this.numberOfChanges = changes
      } else {
        this.numberOfChanges = 0;
      }
    },
    changeIsEqualInOriginalData: function(change){
      let userInOriginalData = this.copyOfUsers.find(u => u.id === change.id);
      let lockInOriginalData = userInOriginalData.locks.find(l => l.id === change.lock.id);
      if (lockInOriginalData) {
        if (lockInOriginalData.can_access === change.lock.can_access && lockInOriginalData.can_manage === change.lock.can_manage) {
          return true
        }
      } else if (change.lock.can_access === false && !lockInOriginalData) {
        //we do not remove the changes for group of locks
        if (change.lock.id.charAt(0).toLowerCase() !== 'g') {
          return true
        }
      }
      return false
    },
    removeFromListOfChanges: function(userId,lockId){
      let user = this.listOfChanges.find(u => u.id === userId)
      let removeIndex = user.locks.map(item => item.id).indexOf(lockId);
      ~removeIndex && user.locks.splice(removeIndex, 1);

      if (user.locks && user.locks.length === 0) {
        removeIndex = this.listOfChanges.map(u => u.id).indexOf(userId);
        ~removeIndex && this.listOfChanges.splice(removeIndex, 1);
      }
    },
    logout: function() {
      SessionService.deleteSession();
      this.$router.push({ name: 'login-view' });
    },
    selfProfile: function () {
      GoogleTagManagerService.pushToDataLayer({event: 'self-profile-click'});
    },
    parse_name: function (usr) {
      let parsed_name = usr.first_name;

      if (usr.last_name) {
        parsed_name = parsed_name + ' ' + usr.last_name
      }

      if (parsed_name === '') {
        parsed_name = usr.phone
      }

      return parsed_name;
    },
    formatDateWithMoment(date){
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
  computed: {
    currentLockGroupCookie: {
      get() {
        return this.$store.getters.currentLockGroup;
      },
      set(newValue) {
        this.$store.dispatch('updateCurrentLockGroup', newValue);
      }
    },
    currentTabCookie: {
      get() {
        return this.$store.getters.currentTab;
      },
      set(newValue) {
        this.$store.dispatch('updateCurrentTab', newValue);
      }
    },
    sortingByCompanyCookie: {
      get() {
        return this.$store.getters.sortingByCompany;
      },
      set(newValue) {
        this.$store.dispatch('updateSortingByCompany', newValue);
      }
    },
    lockObjects: function() {
      let columns = [];
      let columnsObjects = [];
      this.locks.forEach(lock => {
        if (!columns.includes(lock.id)) {
          columns.push(lock.id);
          columnsObjects.push({
            id:lock.id,
            name:lock.name,
            type:lock.type,
            battery_state:lock.battery_state,
            battery_last_reading:lock.battery_last_reading,
            secured_object_id:lock.secured_object_id,
            lock_group_id:lock.lock_group_id,
          })
        }
      });
      return columnsObjects
    },
  }
}

</script>

<style>

.picker-input-class {
  width: 300px !important;
}
.day__month_btn {
  font-weight: bold;
}
.body-overflow {
  overflow: hidden;
}

.md-select-menu {
  z-index: 10000 !important;
}

</style>

<style scoped>

html, body {
  margin: 0;
  font-family: 'Mulish', sans-serif !important;
}
@keyframes openMenu {
  from {max-width: 288px}
  to {max-width: 750px}
}

@keyframes closeMenu {
  from {min-width: 750px}
  to {min-width: 288px;}
}

@keyframes highLightRow {
  from {background-color: #E5DCCF}
  to {background-color: white}
}

@keyframes highLightEvenRow {
  from {background-color: #E5DCCF}
  to {background-color: #faf8f5}
}

@keyframes loadingRow {
  from {background-color: #faf8f5}
  to {background-color: #E1D4BB}
}

#record-container{
  max-height: 320px;
  overflow-y: scroll;
  margin-top: 40px;
}
.scrollable-content{
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}
.label_container{
  text-align: center;
}
.label_border {
  border-bottom: 1px solid #c2a977;
  position: relative;
  top: 10px;
  z-index: 3;
}
.label_data{
  z-index: 4;
  position: relative;
}
.label_data_text {
  background-color: white;
  padding: 20px;
  display: inline;
  color: #766a53;
}
.single-record{
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px 0;
}
.single-record-border{
  border-bottom: 1px solid rgba(194, 169, 119, 0.5);
}
.single-record-element{
  width: 25%;
}
.record-image {
  padding-right: 10px;
  width: 45px;
}
.first-element {
  padding-top: 5px;
  text-align: left;
}
.second-element {
  padding-top: 5px;
  text-align: center;
}
.third-element {
  padding-top: 5px;
  text-align: center;
}
.fourth-element {
  padding-top: 5px;
  text-align: right;
}
.change-name-content{
  display: flex;
  justify-content: space-between;
}


.modal-container-protocol .edit-lock-info {
  font-weight: bold;
}
.lock-selected{
  font-weight: bold;
}
.modal-container-protocol .protocol-info {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}
.select-lock-group, .lock-name-field{
  width: 300px;
}
.select-lock-group{
  display: flex;
  justify-content: space-between;
}
.lock_group_class{
  color: #766a53;
  padding-top: 16px;
  font-size: 16px;
}
.invalid_field_class{
  color: #cc0000;
}
.modal-container-protocol {
  width: 890px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  background-image: url("../../assets/header_background.png");
  background-repeat: repeat-x;
}

.modal-container-protocol .modal-body{
  padding: 10px 30px;
}

.modal-container-protocol .save_user_button{
  margin-top: 18px;
}

.lock-edit-flex{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.lock-edit-flex .save_user_button {
  margin-top: 22px;
}

.highLightRow td{
  animation-name: highLightRow;
  animation-duration: 7s;
  background-color: white
}

.highLightEvenRow td{
  animation-name: highLightEvenRow;
  animation-duration: 7s;
  background-color: #faf8f5
}

.menuIsOpenClass {
  animation-name: openMenu;
  animation-duration: 1s;
  max-width: 750px;
  overflow: hidden;
}

.menuIsClosedClass {
  animation-name: closeMenu;
  animation-duration: 1s;
  max-width: 288px;
  overflow: hidden;
}

.menuIsLoadingClass {
  min-width: 288px;
  max-width: 288px;
  overflow: hidden;
}

.menuContent {
  min-width: 750px;
}

.userEditName md-field {
  margin-top: 0;
  margin-bottom: 0;
}
.firstChild {
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: 1px solid #c2a977;
  vertical-align: bottom;
  padding-bottom: 20px;
}

.caption {
  border-right: 1px solid #c2a977;
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
  z-index: 1;
}
.cellName{
  padding: 0px 180px 18px 40px;
}
.longName{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  text-align: left;
}
.groupOfLocks{
  padding: 16px 180px 10px 40px;
  background-color: #F0ECE5;
  height: 60px;
}
.transponderFirstCell{
  padding: 16px 180px 10px 40px;
  height: 60px;
}
.header-logo {
  padding-left: 20px;
  height: 75px;
  float: left;
}
.header-logo-img {
  height: 75px;
}
.container {
  width: 100%;
  height: 100vh;
  background-color: #fcfbf9;
  margin: 0;
  font-family: Mulish;
}
#header {
  background-color: #000000;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 75px;
  min-width: 400px;
}
.header-content {
  height: 75px;
  min-width: 720px;
}
.content{
  position: absolute;
  top: 207px;
  padding-bottom: 50px;
}

.table {
  border-bottom: 1px solid #c2a977;
  border-right: 1px solid #c2a977;

}
th {
  text-align: left;
}
td {
  text-align: left;
}
table {
  white-space: nowrap;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

table th {
  border-bottom: 1px solid #c2a977;
  padding: 0.5rem 1rem;
}

table td {
  background: #fff;
}

table thead th {
  padding: 3px;
  position: sticky;
  top: 207px;
  z-index: 1;
  min-width: 150px;
  background: white;
}

table tbody th {
  font-style: italic;
  text-align: left;
  position: relative;
}
table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
.battery_image {
  padding-right: 12px;
  margin-top: -2px;
}
.battery_block {
  padding-bottom: 35px;
}
.battery_title {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  padding-bottom: 10px;
}
.last_check{
  padding-left: 38px;
  font-size: 14px;
  color: #888888;
}
.caption {
  border-right: 1px solid #c2a977;
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
  overflow: hidden;
}
.evenRow td{
  background-color: #faf8f5;
}
.cell {
  text-align: center;
}
.cellHeader{
  text-align: left;
  padding: 100px 10px 0px 10px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
}
.cellGroup{
  border-right: 1px dashed #E1D4BB;
}
.userNameClass {
  padding-top: 10px;
  padding-left: 10px;
  text-overflow: ellipsis;
  width: 196px;
  float: left;
  overflow: hidden;
  font-size: 16px;
}
.userImageClass {
  padding-left: 30px;
  float: left;
  width: 68px;
}
.firstLabel {
  padding-left: 30px;
  width: 286px;
  float: left;
  font-size: 14px;
  font-weight: 600;
  padding-top: 30px;
}
.infoLabels {
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  float: left;
  padding-top: 30px;
}
.infoLabelsPhone{
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  float: left;
  padding-top: 30px;
  padding-left: 20px;
}
.infoLabelOne {
  width: 110px;
  font-size: 14px;
  font-weight: 600;
  float: left;
  padding-top: 30px;
}
.infoLabelTwo {
  width: 190px;
  font-size: 14px;
  font-weight: 600;
  float: left;
  padding-top: 30px;
}
.infoClass {
  width: 150px;
  display: block;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.toggleButton {
  position: absolute;
  right: 0;
  top: 120px;
  padding-top: 10px;
  padding-right: 20px;
  background-color: white;
  cursor: pointer;
}
.toggleButtonTransponders {
  position: absolute;
  right: 0;
  top: 105px;
  padding-top: 10px;
  padding-right: 20px;
  background-color: white;
  cursor: pointer;
}
.userMenuClass{
  position: absolute;
  width: 230px;
  right: 20px;
  top: 82px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px 2px #cccccc;
  background-color: white;
  z-index: 1;
}
.editableName{
  cursor: pointer;
}
.cellNameImage{
  padding-left: 28px;
  margin-top: -2px;
}
.userName{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}
.infoLink {
  cursor: pointer;
}
.spacedLink {
  margin-bottom: 20px;
}
.userPhone{
  color: #888888;
}
.userMenuContent{
  border-bottom: 1px solid #f6f0e5;
  padding: 16px;
}
.logoutButton {
  cursor: pointer;
  padding: 16px;
}
.rotateButton {
  padding-left: 20px;
  transform: scaleX(-1);
}
.pointer {
  cursor: pointer;
}
.singleCell {
  margin: auto;
  width: 64px;
  min-height: 36px;
}
.wrapperIcon {
  position: relative;
  min-height: 50px;
}
.loadingAnimation .wrapperIcon,
.loadingAnimation span {
  line-height: 56px;
  cursor: default;
  border-radius: 5px;
  animation-name: loadingRow;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}
.loadingAnimation .userImageClass {
  padding-left: 0;
  margin-left: 30px;
  width: 38px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.loadingAnimation .userNameClass {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.loadingAnimation .userNameClass,
.loadingAnimation .infoClass {
  padding-top: 0;
}
.allRooms {
  border-right: 1px dashed #E1D4BB;
}
.textSelected {
  color: #c2a977;
}
.tabsItem{
  color: #ffffff;
  display: inline;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.tabs-container{
  display: inline;
}
.tabs-container-disabled{
  opacity: 0.5;
  display: inline;
  cursor: default;
}
.tab-selected{
  cursor: default;
  border-bottom: 2px solid #c2a977;
  padding-bottom: 6px;
}
.tab-not-selected{
  cursor: pointer;
  border-bottom: 2px solid #000000;
  padding-bottom: 6px;
}
.tab-not-selected-disabled{
  border-bottom: 2px solid #000000;
  padding-bottom: 6px;
}
.user-status {
  float: right;
  padding: 8px;
}
.add-button {
  cursor: pointer;
  padding: 5px;
}
.disabledButton {
  cursor: default;
  opacity: 0.5;
}
.user-logo {
  cursor: pointer;
}
.information {
  padding-left: 32px;
  padding-top: 18px;
  padding-bottom: 20px;
  background-color: #fcfbf9;
  min-height: 80px;
}
.info_icon {
  padding-top: 6px;
  float: left;
}
.info_text {
  padding-top: 12px;
  padding-left: 6px;
  font-size: 16px;
  float: left;
  letter-spacing: 0.8px;

}
.icon_legenda {
  float: left;
  margin-right: 10px;
  margin-top: 7px;
}

.info_legenda {
  float: left;
  margin-right: 45px;
  margin-left: 6px;
  padding-top: 13px;
  font-weight: 300;
}
.icon_legenda_big {
  float: left;
  margin-right: 10px;
  margin-left: 6px;
  padding-top: 0px;
  font-weight: 300;
}
.first_icon {
  margin-left: 16px;
}
.alert {
  position: fixed;
  left: 0;
  top: 200px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 40px;
  height: 100vh;
}
.disclaimer {
  margin-top: 25px;
}
.disclaimer_icon {
  display: inline-block;
  width: 100px;
}
.disclaimer_icon img{
  margin-bottom: 6px;
}
.alert_title {
  font-size: x-large;
  margin-bottom: 26px;
}
.alert_content {
  margin: 10px;
  line-height: 1.8;
  font-size: 16px;
}
.block_save_button {
  min-width: 1400px;
}
.second_block {
  overflow: hidden;
  padding-left: 34px;
  background-color: white;
  padding-top: 10px;
}
.page_selector_label{
  display: inline-block;
  height: 52px;
  padding-top: 11px;
}
.selector_disabled{
  opacity: 0.3;
}
.group_selector_label{
  display: inline-block;
  height: 52px;
  padding-top: 11px;
  padding-left: 30px
}
.search_field_label{
  display: inline-block;
  height: 52px;
  padding-top: 11px;
  padding-left: 20px;
  padding-right: 35px;
}

.search_field_input_class{
  border: 1px solid #c2a977;
  border-radius: 6px;
  padding: 12px;
}
.search_field_action{
  cursor: pointer;
  background-color: white;
  display: inline-block;
  height: 52px;
}
.search_field_action img{
  margin-left: 10px;
  width: 32px;
}
.page_selector_space{
  display: inline-block;
}
.search_field{
  display: inline-block;
}
.save_user_button {
  margin-right: -8px;
}
.new-user-info {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.5;
  overflow: hidden;
}
.edit-lock-info {
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  margin-bottom: -5px;
}
.myTooltipClass {
  color: #000;
  background-color: #E9E2D6;
  border-radius: 8px;
  font-size: 16px;
  font-family: Mulish;
  padding: 8px 15px 30px 15px;
  margin-top: -16px;
}
.clickableBg {
  background-color: white;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100vh;
}
.loader {
  font-size: 6px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  display: inline-block;
  margin-left: 20px;
}
.widgetUndo{
  position: fixed;
  bottom: 50px;
  left: 40%;
  text-align: center;
  z-index: 1000;
}
.widgetUndoContent{
  display: flex;
  margin: auto;
  background-color: white;
  width: max-content;
  border-radius: 40px;
  padding: 8px 10px;
  border: 1px solid #c2a977;
  box-shadow: 4px 4px 10px #cccccc;
}
.widgetUndoText{
  font-size: 16px;
  padding: 12px;
}
.link-color{
  color: #c2a977;
  font-weight: bold;
}
.action-modal {
  display: flex;
  justify-content: end;
  font-size: 16px;
}
.action-link {
  padding-top: 17px;
  cursor: pointer;
}
.modal-delete .modal-body {
  margin-bottom: 15px;
}
.modal-delete .save_user_button {
  margin-bottom: -10px;
}
.rotate{
  transform: rotate(-65deg);
  transform-origin: 52% 20%;
}
.firstCellName{
  text-align: left;
  padding-left: 40px;
}


@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -2.6em 0 0 #C3AB79, 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2), 2.5em 0 0 0 rgba(195, 171, 121, 0.2), 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.2), 0 2.5em 0 0 rgba(195, 171, 121, 0.2), -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.2), -2.6em 0 0 0 rgba(195, 171, 121, 0.5), -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.7);
  }
  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.7), 1.8em -1.8em 0 0 #C3AB79, 2.5em 0 0 0 rgba(195, 171, 121, 0.2), 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.2), 0 2.5em 0 0 rgba(195, 171, 121, 0.2), -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.2), -2.6em 0 0 0 rgba(195, 171, 121, 0.2), -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.5);
  }
  25% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.5), 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.7), 2.5em 0 0 0 #C3AB79, 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.2), 0 2.5em 0 0 rgba(195, 171, 121, 0.2), -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.2), -2.6em 0 0 0 rgba(195, 171, 121, 0.2), -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2);
  }
  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.2), 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.5), 2.5em 0 0 0 rgba(195, 171, 121, 0.7), 1.75em 1.75em 0 0 #C3AB79, 0 2.5em 0 0 rgba(195, 171, 121, 0.2), -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.2), -2.6em 0 0 0 rgba(195, 171, 121, 0.2), -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2);
  }
  50% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.2), 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2), 2.5em 0 0 0 rgba(195, 171, 121, 0.5), 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.7), 0 2.5em 0 0 #C3AB79, -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.2), -2.6em 0 0 0 rgba(195, 171, 121, 0.2), -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2);
  }
  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.2), 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2), 2.5em 0 0 0 rgba(195, 171, 121, 0.2), 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.5), 0 2.5em 0 0 rgba(195, 171, 121, 0.7), -1.8em 1.8em 0 0 #C3AB79, -2.6em 0 0 0 rgba(195, 171, 121, 0.2), -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2);
  }
  75% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.2), 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2), 2.5em 0 0 0 rgba(195, 171, 121, 0.2), 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.2), 0 2.5em 0 0 rgba(195, 171, 121, 0.5), -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.7), -2.6em 0 0 0 #C3AB79, -1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2);
  }
  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(195, 171, 121, 0.2), 1.8em -1.8em 0 0 rgba(195, 171, 121, 0.2), 2.5em 0 0 0 rgba(195, 171, 121, 0.2), 1.75em 1.75em 0 0 rgba(195, 171, 121, 0.2), 0 2.5em 0 0 rgba(195, 171, 121, 0.2), -1.8em 1.8em 0 0 rgba(195, 171, 121, 0.5), -2.6em 0 0 0 rgba(195, 171, 121, 0.7), -1.8em -1.8em 0 0 #C3AB79;
  }
}
.disabled {
  cursor: wait;
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #c2a977;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #c2a977;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #666666;
  /* Windows High Contrast Mode */
  background-color: #766a53;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  /*outline: max(2px, 0.15em) solid currentColor;*/
  /*outline-offset: max(2px, 0.15em);*/
}

/*input[type="checkbox"]:disabled {*/
/*  --form-control-color: var(--form-control-disabled);*/

/*  color: var(--form-control-disabled);*/
/*  cursor: not-allowed;*/
/*}*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}
td { padding: 0 }
</style>
